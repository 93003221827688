import React, { useState, useEffect, useCallback } from "react";
import "../Css/hospitalspage.css";
import NABH from "../Images/home/Nabh-removebg-preview.png";
import NABL from "../Images/home/NABL.png";
import JCL from "../Images/home/jcl1.png";
import ServiceCall from "../ServiceCall";
import { useNavigate, useLocation, NavLink, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import { countriesList } from "../utility/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import Calenderimg from "../Images/provider/treatment_img.png";
import { styled, useMediaQuery } from "@material-ui/core";
import whatsapp from "../Images/whatsapp.png";
import CenteredAlert from "./CenteredAlert";
import Treatmentmini from "./Treatmentmini";
import { Inter } from "./config";




import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@material-ui/core";
import { colors } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  button: {
    // margin: theme.spacing(2, 0),
    backgroundColor: "#008b91",
    marginTop: "2rem",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#02585c",
    },
  },
  arrowIcon: {
    position: "absolute",
    top: "70px",
    left: theme.spacing(2),
    cursor: "pointer",
  },
  nextButton: {
    backgroundColor: "#008b91",
    marginTop: "2rem",
    color: "#fff",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#02585c",
    },
    "&:disabled": {
      backgroundColor: "#ccc",
      color: "#666",
    },
  },
  recaptcha: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  header: {
    fontSize: "41px",
    marginTop: (props) =>
      props.isMobile ? "69px" : props.step === 1 ? "-293px" : "-362px",

    color: "#fff",
    fontWeight: 600,
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
}));
const HospitalsPage = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [selectedCostType, setSelectedCostType] = useState('Min');
  const [treatdata, setTreatdata] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [countryname, setCountryname] = useState("India");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const[know, setKnow] = useState("");
  const [country, setCountry] = useState("India");
  const [city, setCity] = useState("");
  const isMobile = useMediaQuery("(max-width:710px)");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [isStep1Valid, setIsStep1Valid] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [preferredHospital, setPreferredHospital] = useState('');
const [preferredLocation, setPreferredLocation] = useState('');
const images = treatdata?.images_urls || [];
const [showLoader, setShowLoader] = useState(true); // State to control loader visibility
const Loader = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <CircularProgress />
  </div>
);
  // State for main image
  const [mainImage, setMainImage] = useState("");

  useEffect(() => {
    // Set the main image to the first image when images are loaded
    if (images.length > 0) {
      setMainImage(images[0]); // First image as main image
    }
  }, [images]);

  const smallImages = images.slice(1);
// Define smallImages array
const classes = useStyles({ step, isMobile });
const mode = process.env.REACT_APP_MODE;
const{hospital_route_key} = useParams('');

const isValidPhoneNumber = (phoneNumber) => {
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
  return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
};
useEffect(() => {
  const fetchHospitalData = async () => {
    setShowLoader(true); // Show loader when fetching starts
    try {
      const res = await ServiceCall.gett(`/rxopd/fetch/mvt/hospital/details/${mode}/${hospital_route_key}`);
      if (res?.data) {
        setTreatdata(res.data);
      }
    } catch (err) {
      console.error("Error fetching treatment data:", err);
    } finally {
      setShowLoader(false); // Hide loader when fetching is done (success or failure)
    }
  };

  fetchHospitalData();
}, [mode, hospital_route_key]);


const showAlerts = () => {
  setShowAlert(true);
  resetForm();
};
useEffect(() => {
  const timer = setTimeout(() => {
    setShowLoader(false);
  }, 4000);

  return () => clearTimeout(timer); // Clear timeout on component unmount
}, []);
const dropdownStyle = {
  marginLeft: "1rem",
  listStyle: "none",
  
  borderRadius: "8px",
  padding: "10px 15px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
};
const [openDropdown, setOpenDropdown] = useState(null);
const [expandedQuestions, setExpandedQuestions] = useState([]);
const location = useLocation();
const routekey = location.state?.routekey;
const handleToggleDropdown = (section) => {
  setOpenDropdown(openDropdown === section ? null : section); // Toggle dropdown visibility
};
  // Navigation function for treatment advice page
  const navigate = useNavigate();
  const handleregis = () => {
    navigate("/treatment-advice");
  };

  // Function to toggle the expanded FAQ question
  const toggleQuestion = (id) => {
    if (expandedQuestions.includes(id)) {
      setExpandedQuestions(expandedQuestions.filter((qid) => qid !== id));
    } else {
      setExpandedQuestions([...expandedQuestions, id]);
    }
  };
const resetForm = () => {
  setName("");
  setEmail("");
  setMessage("");
  setAge("");
  setGender("");
  setKnow("");
  setPhone("");
  setCountry("");
  setCity("");
};
const handlesubmit = (e) => {
  e.preventDefault();
  if (!recaptchaValue) {
    alert("Please complete the reCAPTCHA");
    return;
  }

  setLoading(true);
  const serviceid = "service_7yxtqsn";
  const templateid = "template_x3wusd7";
  const publickey = "4UpyJJj3P5JGeMZFz";
  const messageContent = `
  Patient Name: ${name}
  Age: ${age}
  Gender: ${gender}
  Phone Number: ${phoneCode}${phone}
  Email: ${email}
  Country: ${country}
  City: ${city}
  Chief Complaints: ${message}
`;
const templateparams = {
  from_name: name,
  from_email: email,
  to_name: "web",
  message: messageContent,
};

emailjs
  .send(serviceid, templateid, templateparams, publickey)
  .then((response) => {
    setLoading(false);
    console.log("email set successfully", response);
    showAlerts();
  })
  .catch((error) => {
    setLoading(false);
    console.log("error sending email", error);
  });
};
const handlePhoneCodeChange = (event) => {
  setPhoneCode(event.target.value);
};
const handleRecaptchaChange = useCallback((value) => {
  setRecaptchaValue(value);
}, []);



useEffect(() => {
  const isValid =
    name.trim() !== "" &&
    age.trim() !== "" &&
    gender !== "" &&
    message.trim() !== "";
  setIsStep1Valid(isValid);
}, [name, age, gender, message]);
const handleBack = () => {
  setStep(1);
};
const handleNext = () => {
  if (isStep1Valid) {
    setStep(2);
  }
};

const renderStep1 = () => (
  <>
    <TextField
      id="firstName"
      name="first_name"
      label="Patient Name"
      variant="filled"
      style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
      required
      value={name}
      onChange={(e) => setName(e.target.value)}
      fullWidth
      className={classes.nameField}
      InputProps={{
        style: {
          backgroundColor: "#fff", // Set the background color here
        },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
        },
      }}
    />
    <TextField
      id="age"
      name="age"
      label="Patient Age"
      variant="filled"
      value={age}
      onChange={(e) => setAge(e.target.value)}
      style={{ marginBottom: "20px", backgroundColor: "white" }}
      fullWidth
      className={classes.nameField}
      onKeyPress={(e) => {
        // Allow only digits (0-9)
        const allowedChars = /^[0-9]*$/;
        if (!allowedChars.test(e.key)) {
          e.preventDefault();
        }
      }}
      inputProps={{
        maxLength: 3, // Optional: Limits the maximum length to 3 digits
      }}
      InputProps={{
        style: {
          backgroundColor: "#fff",
          marginTop: "7px",
          marginBottom: "-3px", // Set the background color here
        },
      }}
    />
    <FormControl
      variant="filled"
      style={{ marginBottom: "20px" }}
      fullWidth
      className={classes.nameField}
      classes={{ root: classes.root }} // Apply the custom styles
    >
      <InputLabel id="gender">Gender</InputLabel>
      <Select
        labelId="gender"
        id="gender"
        name="gender"
        value={gender}
        onChange={(e) => setGender(e.target.value)}
        classes={{ root: classes.root }} // Apply the custom styles
      >
        <MenuItem value="male">Male</MenuItem>
        <MenuItem value="female">Female</MenuItem>
        <MenuItem value="other">Other</MenuItem>
      </Select>
    </FormControl>

    <TextField
      multiline
      maxRows={4}
      id="chiefComplaints"
      name="message"
      label="Describe current medical problem"
      variant="filled"
      value={message}
      onChange={(e) => setMessage(e.target.value)}
      required
      fullWidth
      style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
      className={classes.nameField}
      InputProps={{
        style: {
          backgroundColor: "#fff", // Set the background color here
        },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
        },
      }}
    />

    <TextField
      label="Preferred Hospital (Optional)"
      value={preferredHospital}
      onChange={(e) => setPreferredHospital(e.target.value)}
      fullWidth
      style={{ marginBottom: "20px", backgroundColor: "white" }}
      className={classes.nameField}
      InputProps={{
        // style: {
        //   backgroundColor: "#fff",
        // },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" },
        },
      }}
    />

    <TextField
      label="Preferred Treatment Location (Optional)"
      value={preferredLocation}
      onChange={(e) => setPreferredLocation(e.target.value)}
      fullWidth
      style={{ marginBottom: "20px" }}
      className={classes.nameField}
      InputProps={{
        // style: {
        //   backgroundColor: "#fff",
        // },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" },
        },
      }}
    />
    <Button
      className={classes.nextButton}
      variant="contained"
      onClick={handleNext}
      fullWidth
      disabled={!isStep1Valid}
    >
      Next
    </Button>
  </>
);

const renderStep2 = () => (
  <>
    <FormControl variant="filled" fullWidth className={classes.nameField}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="w-23"
          value={phoneCode} // Concatenate phoneCode and phone here
          onChange={handlePhoneCodeChange}
          classes={{ root: classes.root }}
          style={{ marginTop: "16px" }}
        >
          {countriesList &&
            countriesList.map((item, index) => (
              <MenuItem
                value={item.code}
                key={item.code + "-" + index}
                classes={{ root: classes.menuItem }} // Apply the custom styles to the MenuItem
              >
                {item.code}
              </MenuItem>
            ))}
        </Select>

        <InputLabel htmlFor="phoneNumber" style={{ marginLeft: "101px" }}>
          Phone Number*
        </InputLabel>
        <Input
          id="phoneNumber"
          name="phone"
          label="Phone Number"
          required
          value={phone}
          onChange={(e) => {
            const value = e.target.value;
            const allowedChars = /^[0-9]*$/; // Allow only digits

            if (allowedChars.test(value)) {
              setPhone(value.slice(0, 10)); // Limit the input to 10 digits
              setPhoneError(false); // Reset the error state
            } else {
              setPhoneError(true); // Set the error state if non-digit characters are entered
            }
          }}
          error={phoneError}
          style={{ flex: 1, marginLeft: 6, width: "100px" }}
          className={classes.nameField}
          InputProps={{
            style: {
              backgroundColor: "#fff",
            },
          }}
        />
      </div>
      {phoneError && (
        <FormHelperText error>
          Please enter a valid phone number.
        </FormHelperText>
      )}
    </FormControl>
    <TextField
      id="email"
      name="email"
      label="Email"
      variant="filled"
      type="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      fullWidth
      required
      style={{ marginBottom: "20px", backgroundColor: "white" }}
      className={classes.nameField}
      inputProps={{
        pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
        title: "Please enter a valid email address",
      }}
      InputProps={{
        style: {
          backgroundColor: "#fff", // Set the background color here
        },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
        },
      }}
    />
    <FormControl
      id="country"
      name="country"
      variant="filled"
      required
      fullWidth
      style={{ marginBottom: "20px", backgroundColor: "white" }}
      className={classes.nameField}
    >
      <InputLabel id="gender">Select your country</InputLabel>
      <Select
        labelId="country"
        id="country"
        className="w-23"
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        classes={{ root: classes.root }}
        InputProps={{
          style: { backgroundColor: "#fff" },
          inputProps: { style: { maxWidth: "100px" } },
        }}
        MenuProps={{
          components: {
            MenuItem: (props) => (
              <MenuItem
                {...props}
                classes={{ root: classes.menuItem }}
                style={{ maxWidth: "100px" }}
              >
                {props.children}
              </MenuItem>
            ),
          },
        }}
      >
        {countriesList &&
          countriesList.map((item, index) => (
            <MenuItem value={item.name} key={`${item.name}-${index}`}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
    <FormControl
      variant="filled"
      style={{ marginBottom: "20px" }}
      fullWidth
      className={classes.nameField}
      classes={{ root: classes.root }} // Apply the custom styles
    >
      <InputLabel id="know">How Did you know about this</InputLabel>
      <Select
        labelId="know"
        id="know"
        name="know"
        value={know}
        onChange={(e) => setKnow(e.target.value)}
        classes={{ root: classes.root }} // Apply the custom styles
      >
        <MenuItem value="google search">Google Search</MenuItem>
        <MenuItem value="facebook">Facebook</MenuItem>
        <MenuItem value="friends/relatives">Friends/Relatives</MenuItem>
        <MenuItem value="past patients">Suggested by past patients</MenuItem>
        <MenuItem value="ambassador">Ambassador</MenuItem>
        <MenuItem value="other">Others</MenuItem>
      </Select>
    </FormControl>
    <TextField
      id="city"
      name="city"
      label="Enter your city"
      value={city}
      onChange={(e) => setCity(e.target.value)}
      variant="filled"
      required
      fullWidth
      style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
      className={classes.nameField}
      InputProps={{
        style: {
          backgroundColor: "#fff", // Set the background color here
        },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
        },
      }}
    />
    <span
      style={{
        fontSize: "11px",
        fontWeight: "bold",
        textAlign: "center",
      }}
    >
      *We value your privacy, your details will be kept confidential. Refer
      our <a href="https://rxone.app/privacypolicy">Privacy Policy</a>
    </span>
    <div className={classes.recaptcha}>
      <ReCAPTCHA
        sitekey="6LddplcqAAAAACztSGsmgN9xckbNg_5mn4p4t3A5"
        onChange={handleRecaptchaChange}
      />
    </div>
    <Button
      className={classes.button}
      variant="contained"
      color="primary"
      type="submit"
      fullWidth
      disabled={loading || !recaptchaValue}
    >
      {loading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        "Request Callback"
      )}
    </Button>
  </>
);
  // Scroll to the clicked section and highlight it
  const scrollToSection = (id) => {
    if (activeSection === id) return; // Avoid redundant updates
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -60; // Offset for fixed headers
      const yPosition =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({
        top: yPosition,
        behavior: "smooth",
      });
      setActiveSection(id); // Set the active section
    }
  };

  useEffect(() => {
    let timeoutId = null;

    const handleScroll = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const sections = [
          "about-section",
          "doctors-section",
          "specialities-section",
          "facilities-section",
          "location-section",
        ];

        // Find the section currently in view
        for (const id of sections) {
          const element = document.getElementById(id);
          if (element) {
            const rect = element.getBoundingClientRect();
            if (rect.top <= 70 && rect.bottom >= 70) {
              setActiveSection(id); // Highlight the section in view
              break;
            }
          }
        }
      }, 100); // Debounce scroll updates
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
    {showLoader ? (
      <Loader />
    ) : (
      <>
      {/* Hospital Banner */}
      <section className="ms-book-banner d-flex align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="offset-md-3 col-md-6" style={{ height: "5px" }}>
              <h3 className="mb-3 ms-font-weight-600 text-center"></h3>
            </div>
          </div>
        </div>
      </section>

      {/* Hospital Details */}
      <section className="outer-container">
        <div className="inner-container">
        <div 
  className="hospital-header1" 
  style={{
    display: "flex", // Enable horizontal alignment
    alignItems: "center", // Align content vertically
    gap: "20px", // Add spacing between button and logos
  }}
>
  {/* Button */}
  

  {/* Logos Container */}
  <div className="logos-container">
  {/* Render NABH Logo */}
  {treatdata?.hosp_accreditation_by_list?.includes("NABH") && (
    <img src={NABH} alt="NABH Logo" className="hospital-logo nabh-logo" />
  )}

  {/* Render NABL Logo */}
  {treatdata?.hosp_accreditation_by_list?.includes("NABL") && (
    <img src={NABL} alt="NABL Logo" className="hospital-logo nabl-logo" />
  )}

  {/* Render JCL Logo */}
  {treatdata?.hosp_accreditation_by_list?.includes("JCL") && (
    <img src={JCL} alt="JCL Logo" className="hospital-logo jcl-logo" />
  )}
</div>

<div className="top-choice-button">
  <span>Top Choice</span>
</div>

</div>


          <section className="hospital-page">
          <div className="hospital-left">
  <div className="hospital-header">
    <img 
      src={treatdata?.logo_url} 
      alt="Hospital Logo" 
      className="hospital-logo2" 
    />
    <h2 className="hospital-name">
      {treatdata?.hosp_name}<br></br>
      <span className="hospital-city">
        {treatdata?.city}
      </span>
    </h2>
    <span className="hospital-rating">⭐⭐⭐⭐⭐</span>
  </div>

  <div className="hospital-gallery">
    <div className="main-image-container">
      {mainImage ? (
        <img src={mainImage} alt="Main Hospital" className="hospital-image" />
      ) : (
        <p>Loading main image...</p>
      )}
    </div>
    <div className="thumbnail-container">
      {smallImages.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={ `${index + 1}`}
          className="thumbnail"
          onClick={() => setMainImage(image)}
        />
      ))}
    </div>
  </div>
</div>


            <div className="hospital-right">
              <h3 className="hospital-heading">About of {treatdata?.hosp_name} ({treatdata?.city})</h3>
              <p className="hospital-details">
               <ul>
                    {treatdata?.facility_description_list}
                  </ul>
</p>

            </div>
            
          </section>

          {/* Navigation Table */}
          <section className="hospital-table-section">
            <table className="hospital-table">
              <tbody>
                <tr>
                <td>
  Accreditation<br />
  {treatdata?.hosp_accreditation_by_list?.length > 0 
    ? treatdata.hosp_accreditation_by_list.join(', ') 
    : 'No accreditation available'}
</td>

                  <td>
                    Established <br></br>{treatdata?.established}
                  </td>
                  <td>
                    Experiance <br></br>{treatdata?.experience}
                  </td>
                  <td>
                    No. of beds <br></br>{treatdata?.beds_count}
                  </td>
                </tr>
                <tr>
                <td
                    className={activeSection === "doctors-section" ? "active" : ""}
                    onClick={() => scrollToSection("doctors-section")}
                  >
                    Top Department<br />(Click)
                  </td>
                  <td
                    className={activeSection === "specialities-section" ? "active" : ""}
                    onClick={() => scrollToSection("specialities-section")}
                  >
                    Specialities<br />(Click)
                  </td>
                  <td
                    className={activeSection === "facilities-section" ? "active" : ""}
                    onClick={() => scrollToSection("facilities-section")}
                  >
                    Facilities<br />(Click)
                  </td>
                  <td
                    className={activeSection === "location-section" ? "active" : ""}
                    onClick={() => scrollToSection("location-section")}
                  >
                    Location<br />(Click)
                  </td>
                 
                  
                </tr>
              </tbody>
            </table>
          </section>

          {/* Scrollable Sections */}
          <div>
          {[
            
            {
              content: (
                <>
                  <h2>Infrastructure</h2>
                  {/* <p>{treatdata?.hospital_name} </p> */}
                  
    <p>{treatdata?.infrastructure_list}</p>
    
  
                </>
              )
            },
            {
              id: "doctors-section",
              content: (
                <>
                  <h2>Top Department</h2>
                  <ul className="horizontal-list doctors-list">
                    {treatdata?.top_doctors_in_departments_list?.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </>
              ),
            },
            {
              id: "specialities-section",
              content: (
                <>
                  <h2>Specialities</h2>
                  <p>Information about specialties offered at the hospital:</p>
                  <ul className="horizontal-list specialities-list">
                    {treatdata?.team_and_specialities_list?.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </>
              ),
            },
                      
          
            {
              id: "facilities-section",
              content: (
                <>
                  <div id="facilities-section">
                    <h2>Facilities</h2>
                    <ul style={{ listStyleType: "disc", paddingLeft: "20px", color: "#01A09E" }}>
                      {/* Comfort During Stay */}
                      <li
                        onClick={() => handleToggleDropdown("comfort")}
                        style={{
                          cursor: "pointer",
                          fontWeight: "600",
                          color: "#01A09E", // Top-level color
                        }}
                      >
                        Comfort During Stay <span>{openDropdown === "comfort" ? "▲" : "▼"}</span>
                        {openDropdown === "comfort" && (
                          <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                            {treatdata?.facilities?.comfort_during_stay?.map((item, index) => (
                              <li key={index} style={{ color: "#01A09E" }}> {/* Dropdown color */}
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
            
                      {/* Food */}
                      <li
                        onClick={() => handleToggleDropdown("food")}
                        style={{
                          cursor: "pointer",
                          fontWeight: "600",
                          color: "#01A09E", // Top-level color
                        }}
                      >
                        Food <span>{openDropdown === "food" ? "▲" : "▼"}</span>
                        {openDropdown === "food" && (
                          <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                            {treatdata?.facilities?.food?.map((item, index) => (
                              <li key={index} style={{ color: "#01A09E" }}> {/* Dropdown color */}
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
            
                      {/* Treatment Related */}
                      <li
                        onClick={() => handleToggleDropdown("treatment")}
                        style={{
                          cursor: "pointer",
                          fontWeight: "600",
                          color: "#01A09E", // Top-level color
                        }}
                      >
                        Treatment Related <span>{openDropdown === "treatment" ? "▲" : "▼"}</span>
                        {openDropdown === "treatment" && (
                          <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                            {treatdata?.facilities?.treatment_related?.map((item, index) => (
                              <li key={index} style={{ color: "#01A09E" }}> {/* Dropdown color */}
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
            
                      {/* Language */}
                      <li
                        onClick={() => handleToggleDropdown("language")}
                        style={{
                          cursor: "pointer",
                          fontWeight: "600",
                          color: "#01A09E", // Top-level color
                        }}
                      >
                        Language <span>{openDropdown === "language" ? "▲" : "▼"}</span>
                        {openDropdown === "language" && (
                          <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                            {treatdata?.facilities?.language?.map((item, index) => (
                              <li key={index} style={{ color: "#01A09E" }}> {/* Dropdown color */}
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
            
                      {/* Transportation */}
                      <li
                        onClick={() => handleToggleDropdown("transportation")}
                        style={{
                          cursor: "pointer",
                          fontWeight: "600",
                          color: "#01A09E", // Top-level color
                        }}
                      >
                        Transportation <span>{openDropdown === "transportation" ? "▲" : "▼"}</span>
                        {openDropdown === "transportation" && (
                          <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                            {treatdata?.facilities?.transportation?.map((item, index) => (
                              <li key={index} style={{ color: "#01A09E" }}> {/* Dropdown color */}
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    </ul>
                  </div>
                </>
              ),
            },
            
    
          {
            id: "location-section",
            content: (
              <>
              <h2>Location</h2> 
                <p>
             {treatdata?.location?.address}
                </p>
              </>
            ),
          },
          
          
          ].map(({ id, title, content }) => (
            <section
              key={id}
              id={id}
              className={`scroll-section ${activeSection === id ? "highlight" : ""}`}style={{backgroundColor:"rgb(229, 227, 227) " ,color:"#01A09E"}}
            >
              <h3 style={{color:"#01A09E"}}>{title}</h3>
              <p style={{color:" var(--text-light)"}}>{content}</p>
            </section>
            
          ))}
          </div>
        </div>
      </section>
      <Treatmentmini/>
      <section data-aos-duration="3000" className="ms-cureways-section">
        <span
          style={{
            padding: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row", // Change flex direction based on screen size
            marginTop: isMobile ? "1rem" : "0",
            marginTop: "4rem",
            marginBottom: "-7.5rem",
          }}
        />
        <div
          style={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: isMobile ? "column" : "row", // Change flex direction based on screen size
            marginTop: isMobile ? "-77px" : "0",
            marginLeft: "20%"
          }}
        >
          <div
            style={{
              maxWidth: isMobile ? "100%" : "45%", // Set maxWidth to 100% for mobile
              // textAlign: "center", // Center align text on mobile
              marginTop: isMobile ? "0" : "355px", // Remove negative margin on mobile
            }}
          >
           <h1
      className={classes.header}
      style={{
        marginLeft: isMobile ? '10px' : '-1rem', // Adjust margin depending on mobile or desktop
        fontSize: isMobile ? '2rem' : '2.5rem', // Decrease font size for mobile
      }}
    >
      Your Health, Our Priority
      <span style={{ color: '#fff' }}></span>
    </h1>
            <ul className="mb-2">
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Save upto 40% treatment costs in India
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Get treatment in the top hospitals in India 
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Connect with top doctors and specialists in India
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Discover the best treatment options available for you
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Receive a personalized care plan tailored to your needs
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Feel confident with top-level support throughout care journey
              </li>
            </ul>

            <p
      style={{
        fontWeight: 'bold',
        color: '#fff',
        fontSize: isMobile ? '20px' : '21px', // Adjust font size based on screen size
        lineHeight: isMobile ? '1.3' : '1.5', // Optional: Adjust line height for readability
        marginLeft: isMobile ? '30px' : '34px',
      }}
    >
      ....And get assistance for everything in between
    </p>

            {/* <img src={Calenderimg} style={{textAlign: "center", marginTop: "2rem",  height: "26rem", width: "27rem", borderRadius: "31px", marginLeft: isMobile ? "-1.3rem" : ""}} /> */}

          </div>
          <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        order: isMobile ? 3 : 2
      }}
    >
      </div>
      {/* WhatsApp Button */}
      <div className="whatsapp-block" style={{ marginBottom: "2rem" }}>
        
      <a
                href="https://api.whatsapp.com/send?phone=+917703934446&text=Hello%20RxOne,%20Could%20you%20please%20call%20back."
                target="_blank"
                rel="noopener noreferrer"
              >
          <img src={whatsapp} alt="whtsp" />
        </a>
      </div>
          <div style={{ width: isMobile ? "100%" : "auto" }}>
        <Container component="main" maxWidth="xs">
          <div
            className="provider_revolutionize_item"
            style={{
              backgroundColor: "#fff",
              width: "100%",
              borderRadius: "55px",
              boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 16px",
              position: "relative",
              padding: "2rem",
            }}
          >
            {step === 2 && (
              <ArrowBackIosNewIcon
                className={classes.arrowIcon}
                onClick={handleBack}
              />
            )}
            <h4
              className="ms-font-weight-600 mb-4 ms-primary-color"
              style={{ textAlign: "center" }}
            >
              Contact Us
            </h4>
            <form
              style={{ textAlign: "center", width: "100%" }}
              onSubmit={handlesubmit}
            >
              {step === 1 ? renderStep1() : renderStep2()}
            </form>
          </div>
        </Container>
      </div>
        </div>
      </section>
      
      {showAlert && (
        <CenteredAlert
          message="We have received your request, please allow us up-to 24 Hrs. to come back to you."
          onClose={() => setShowAlert(false)}
        />
      )}
       

          <h5
        className="mb-4 ms-font-weight-600 ms-primary-color"
        style={{ marginLeft: "1rem", fontSize: "1.4rem" }}
      >
        Frequently Asked Questions
      </h5>

      <ul style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
        {Inter.map((Inter) => (
          <li className="languages card" key={Inter.id}>
            <div>
              <strong>{Inter.question}</strong>
              <button
                onClick={() => toggleQuestion(Inter.id)}
                style={{
                  border: "none",
                  background: "none",
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  marginTop: "-22px",
                }}
              >
                <span>{expandedQuestions.includes(Inter.id) ? "▲" : "▼"}</span>
              </button>
            </div>
            {expandedQuestions.includes(Inter.id) && <p>{Inter.answer}</p>}
          </li>
        ))}
      </ul>
      </>
    )}
  </>
       
  );
};

export default HospitalsPage;