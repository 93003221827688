import { useState, useEffect } from "react";
import ServiceCall from "../ServiceCall";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../Css/Doctorprofile.css";
import LanguageIcon from "@mui/icons-material/Language";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import EarbudsIcon from "@mui/icons-material/Earbuds";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import { Helmet } from "react-helmet";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { countriesList } from "../utility/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import backgroundimg from "../Images/banner-bg.jpg";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Modal, Button } from 'react-bootstrap';
import CenteredAlert from "./CenteredAlert";
import {
  TextField,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@material-ui/core";
import emailjs from "emailjs-com";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
  salutationFormControl: {
    width: "27%", // Adjust the width of the salutation select
  },
  gender: {
    width: "30%",
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  nameField: {
    background: "white",
  },
  select: {
    border: "none", // Remove border
    width: "50px", // Adjust the width as needed
  },
}));

const Doctorprofile = () => {
  const mode = process.env.REACT_APP_MODE;
  const [doctordata, setDoctordata] = useState("");
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const { route_key } = useParams();
  const patientportal = process.env.REACT_APP_PATIENTPORTAL;
  const book_Appointment = process.env.REACT_APP_BOOK_WIDGET;
  const [showModal, setShowModal] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');
  const [showModals, setShowModals] = useState(false);
  const classes = useStyles();
  const [phoneError, setPhoneError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [pin, setPin] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate("");

  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true); // State to control loader visibility
  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );

  const showAlerts = () => {
    setShowAlert(true);
    resetForm();
  };

  const resetForm = () => {
    setName("");
    setMessage("");
    setPhone("");
  };

  const handlehos = () => {
    if(doctordata?.hospital_route_key){
      navigate(`/hospitalpage/${doctordata?.hospital_route_key}`);
    }
  };

  const handlesubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const serviceid = "service_7yxtqsn";
    const templateid = "template_x3wusd7";
    const publickey = "4UpyJJj3P5JGeMZFz";
    const messageContent = `
    Type: 'Appointment Booking For- ${doctordata?.doctor_card?.doctor_name}'
    Patient Name: ${name}
    Phone: ${phoneCode}${phone}
    Problem: ${message}

  `;

    const templateparams = {
      from_name: name,
      from_email: email,
      to_name: "web",
      message: messageContent, // Include the messageContent here
    };

    emailjs
      .send(serviceid, templateid, templateparams, publickey)
      .then((response) => {
        setLoading(false);
        console.log("email set successfully", response);
        showAlerts();
        
      })
      .catch((error) => {
        setLoading(false);
        console.log("error sending email", error);
      });
  }; 


  const handlePhoneCodeChange = (event) => {
    setPhoneCode(event.target.value);
  };

  useEffect(() => {
    setShowLoader(true);

    const doctorprofile = async () => {
      try {
        // if (!selectedSpecialty) {
        //   console.log("Selected specialty is not set");
        //   return;
        // }
        const profiledata = await ServiceCall.get(
          `/v2/patient/fetch/open/doctor/profile/${mode}/${route_key}`
        );

        if (profiledata) {
          setShowLoader(false);
          setDoctordata(profiledata.data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    doctorprofile(); // Call the function immediately on mount
  }, []); // Empty dependency array ensures this effect runs only once, on mount

  // useEffect(() => {
  //   if (location?.state?.specialties) {
  //     setSelectedSpecialty(location.state.specialties);
  //   }
  // }, [location]);

  // Include selectedSpecialty in the dependency array

  const toggleQuestion = (id) => {
    if (expandedQuestions.includes(id)) {
      setExpandedQuestions(expandedQuestions.filter((qid) => qid !== id));
    } else {
      setExpandedQuestions([...expandedQuestions, id]);
    }
  };
  const handleID = (res, event) => {
    console.log(333, res)
    // setDoctorID(res.doc_id);
    // setHospitalID(res.hos_id);
    // setDoctorDetails(res);
    // setPerson(event);
    if(res.show_booking_button === false){

      setShowModals(true)
    }else if(res.show_booking_button === true){

      const url = `${book_Appointment}${route_key}`;
      setIframeUrl(url);
      setShowModal(true);
    }

  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false); // Hide loader after 2 seconds
    }, 4000);

    return () => clearTimeout(timer); // Clear timeout on component unmount
  }, []);

  const unicode = "\u20B9";

  const titledata = doctordata.meta_description;

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <>
          <div className="container" style={{ marginTop: "100px" }}>
            <Helmet>
              {
                <meta
                  name="description"
                  content={doctordata.meta_description}
                />
              }
            </Helmet>
            <Helmet>{<title>{doctordata.title}</title>}</Helmet>

            <Helmet>
              <meta property="og:title" content={doctordata.meta_description} />
              <meta
                property="og:description"
                content={doctordata.meta_description}
              />
              <meta
                property="og:image"
                content={`${ServiceCall.images(
                  `v2/patient/doctor/pic/route/key/${mode}/${route_key}`
                )}`}
              />
              <meta
                property="og:url"
                content={`https://rxone.app/profile/${route_key}`}
              />
              <meta property="og:type" content="website" />
            </Helmet>
            <Helmet>
              <meta
                name="twitter:title"
                content={doctordata.meta_description}
              />
              <meta
                name="twitter:description"
                content={doctordata.meta_description}
              />
              <meta
                name="twitter:image"
                content={`${ServiceCall.images(
                  `v2/patient/doctor/pic/route/key/${mode}/${route_key}`
                )}`}
              />
              {/* <meta name="twitter:card" content="summary_large_image" />  */}
            </Helmet>

            <Helmet>
              {/* Dynamically set meta tags */}
              {doctordata &&
                doctordata.seo.map((keyword, index) => (
                  <meta name="keywords" content={keyword} key={index} />
                ))}
            </Helmet>
            {/* <Helmet>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6196103748463268"
     crossorigin="anonymous"></script>
            </Helmet> */}

            {doctordata ? (
              <>
                <div
                  // data-aos="fade-up"
                  className="ms-doctors"
                  style={{
                    marginTop: "0px",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <button
                    className="tele__btn ms-border-rounded ms-btn"
                    type="button"
                    style={{
                      marginBottom: doctordata?.social_links ? "-96px" : "0",
                      float: "inline-end",
                      marginTop: "10px",
                      backgroundColor: "#ffa000",
                      color: "#fff",
                      border: "2px solid #ffa000"
                    }}
                    onClick={() => handleID(doctordata, "True", "Clinic")}
                  >
                    <a style={{ color: "white" }}>
                      {/* {doctordata.appointment_booking_button_title} */}
                      Book Appointment
                    </a>{" "}
                  </button>
{doctordata.social_links && (


                  <div
                    className="sociallinks "
                    style={{ float: "inline-end", marginTop: "90px" }}
                  >
                    <a href={doctordata.social_links.facebook || "#"}>
                      {/* <i className="fab fa-facebook-f ms-icon"></i> */}
                      <FacebookIcon />
                    </a>
                    <a href={doctordata.social_links.twitter || "#"}>
                      {/* <i className="fab fa-twitter ms-icon"></i> */}
                      <TwitterIcon />
                    </a>
                    <a href={doctordata.social_links.linkedin || "#"}>
                      {/* <i className="fab fa-linkedin-in ms-icon"></i> */}
                      <LinkedInIcon />
                    </a>
                    <a href={doctordata.social_links.instagram || "#"}>
                      {/* <i className="fab fa-instagram ms-icon"></i> */}
                      <InstagramIcon />
                    </a>
                  </div>
)}
                  <div className="row">
                    <div className="col-lg-4">
                      <img
                        // src={backgroundimg}
                        style={{
                          width: "100%",
                          height: "300px",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          zIndex: -1, // To place it behind other content
                          background: "#018C92",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                        }}
                      />
                      <div className="dta">
                        <div className="docimage">
                          <img
                            src={ServiceCall.images(
                              `v2/patient/doctor/pic/route/key/${mode}/${route_key}`
                            )}
                            // className="img-fluid ms-doc-img"
                            alt={doctordata.doctor_card.doctor_name}
                          />

                          {console.log( 
                            ServiceCall.images(
                              `v2/patient/doctor/pic/route/key/${mode}/${route_key}`
                            )
                          )}
                          {doctordata?.doctor_card?.online_discount_stmt !==
                            "Get upto 0% discount on advance booking!" && (
                            <div className="discount">
                              <h5>
                                <strong>
                                  {
                                    doctordata?.doctor_card
                                      ?.online_discount_stmt
                                  }
                                </strong>
                              </h5>
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className="ms-font-weight-600 mb-3"
                        style={{ marginTop: "10px" }}
                      >
                        <h1
                          className="ms-font-weight-600 mb-4 ms-primary-color"
                          style={{ fontSize: "22px" }}
                        >
                          {doctordata.doctor_card.doctor_name}
                        </h1>
                        <h5
                          className="mb-0"
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#5e5d5d",
                          }}
                        >
                          {doctordata.doctor_card.speciality}
                        </h5>
                        <h5
                          className="mb-0"
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#5e5d5d",
                          }}
                        >
                          {doctordata.doctor_card.experience} years
                        </h5>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                      className="col-lg-8"
                    >
                      <div
                        style={{
                          display: "inline-block",
                          cursor: "pointer",
                        }}
                        onClick={handlehos}
                      >
                        <h6
                          style={{
                            fontWeight: "500",
                            fontSize: "17px",
                            color: "black",
                          }}
                        >
                          <LocalHospitalIcon />
                          {doctordata.doctor_card.hospital.toUpperCase()}
                        </h6>
                        <h6
                          style={{
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          <AddLocationIcon />
                          {doctordata.doctor_card.address.toUpperCase()},{" "}
                          {doctordata.doctor_card.city}
                        </h6>
                      </div>
                    </div>
                    {/* About the Doctor */}
                  </div>
                </div>

                {doctordata.body_cards[2] &&
                  doctordata.body_cards[2].object.length > 0 && (
                    <div className="languages card">
                      <h5 className=" mb-1">
                        <PersonIcon style={{ margin: "10px" }} />
                        {doctordata?.body_cards?.[2]?.heading}
                      </h5>
                      <ul>
                        {doctordata.body_cards[2]?.object.map((about) => (
                          <p key={about?.title}>
                            {about?.title} <br />
                            {about?.description && (
                              <span style={{ color: "gray" }}>
                                {about?.description}
                              </span>
                            )}
                          </p>
                        ))}
                      </ul>
                    </div>
                  )}

                <div className="schedule-container">
                  {doctordata.schedule_cards.map((schedule, index) => (
                    <div key={index} className="langua card">
                      <h5 className=" mb-1">
                        {schedule.title === "VIDEO CONSULTATION" && (
                          <VideoLabelIcon style={{ marginRight: "4px" }} />
                        )}
                        {schedule.title === "IN-CLINIC VISITS" && (
                          <LocalHospitalIcon />
                        )}
                        {schedule.title
                          .toLowerCase()
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </h5>
                      <div className="schedule-card">
                        <p>
                          <CalendarTodayIcon /> {schedule.days.join(", ")}
                        </p>
                        <p>
                          <ScheduleOutlinedIcon /> {schedule.time.start_time} -{" "}
                          {schedule.time.end_time}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                {doctordata.body_cards[0] &&
                  doctordata.body_cards[0].object.length > 0 && (
                    <div className="languages card">
                      <h5 className=" mb-1">
                        <LanguageIcon style={{ margin: "10px" }} />
                        {doctordata?.body_cards?.[0]?.heading}
                      </h5>
                      <ul>
                        {doctordata.body_cards[0]?.object.map((lang) => (
                          <li key={lang?.title}>
                            {lang?.title} <br />
                            {lang?.description && (
                              <span style={{ color: "gray" }}>
                                ({lang?.description})
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                {doctordata.body_cards[1] &&
                  doctordata.body_cards[1].object.length > 0 && (
                    <div className="languages card">
                      <h5 className=" mb-1">
                        <SchoolIcon style={{ margin: "10px" }} />
                        {doctordata?.body_cards?.[1]?.heading}
                      </h5>
                      <ul>
                        {doctordata.body_cards[1]?.object.map((experience) => (
                          <li key={experience?.title}>
                            {experience?.title} <br />
                            {experience?.description && (
                              <span style={{ color: "gray" }}>
                                ({experience?.description})
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                {doctordata.body_cards[5] &&
                  doctordata.body_cards[5].object.length > 0 && (
                    <div className="languages card">
                      <h5 className=" mb-1">
                        <CastForEducationIcon style={{ margin: "10px" }} />
                        {doctordata.body_cards[5].heading}
                      </h5>
                      <ul>
                        {doctordata.body_cards[5]?.object.map(
                          (qualification) => (
                            <li key={qualification.title}>
                              {qualification.title} <br />
                              {qualification.description && (
                                <span style={{ color: "gray" }}>
                                  ({qualification.description})
                                </span>
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}

                {doctordata.body_cards[4] &&
                  doctordata.body_cards[4].object.length > 0 && (
                    <div className="languages card">
                      <h5 className=" mb-1">
                        <HowToRegIcon style={{ margin: "10px" }} />
                        {doctordata?.body_cards[4]?.heading}
                      </h5>
                      <ul>
                        {doctordata.body_cards[4]?.object.map(
                          (registration) => (
                            <li key={registration.title}>
                              {registration.title} <br />
                              {registration.description && (
                                <span style={{ color: "gray" }}>
                                  ({registration.description})
                                </span>
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}

                {doctordata.body_cards[3] &&
                  doctordata.body_cards[3].object.length > 0 && (
                    <div className="languages card">
                      <h5 className=" mb-1">
                        <EarbudsIcon style={{ margin: "10px" }} />
                        {doctordata?.body_cards?.[3]?.heading}
                      </h5>
                      <ul>
                        {doctordata.body_cards[3]?.object.map(
                          (specialization) => (
                            <li key={specialization.title}>
                              {specialization.title}
                              <br />
                              {specialization.description && (
                                <span style={{ color: "gray" }}>
                                  ({specialization.description})
                                </span>
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}

                {doctordata.body_cards[6] &&
                  doctordata.body_cards[6].object.length > 0 && (
                    <div className="languages card">
                      <h5 className=" mb-1">
                        <AirlineSeatReclineExtraIcon
                          style={{ margin: "10px" }}
                        />
                        {doctordata?.body_cards?.[6]?.heading}
                      </h5>
                      <ul>
                        {doctordata.body_cards[6]?.object.map((service) => (
                          <li key={service.title}>
                            {service?.title} <br />
                            {service?.description != null && (
                              <span style={{ color: "gray" }}>
                                ({service?.description})
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                <h5 className=" mb-1">Frequently Asked Questions</h5>

                <ul style={{ paddingLeft: "0rem" }}>
                  {doctordata.faqs.object.map((faq) => (
                    <li className="languages card" key={faq.id}>
                      <div>
                        <strong>{faq.question}</strong>
                        <button
                          onClick={() => toggleQuestion(faq.id)}
                          style={{
                            border: "none",
                            background: "none",
                            display: "flex",
                            justifyContent: "end", // Aligns content to the end
                            width: "100%", // Ensures the icon is at the end even if the button size changes
                            marginTop: "-22px",
                          }}
                        >
                          <span>
                            {expandedQuestions.includes(faq.id) ? "▲" : "▼"}
                          </span>
                        </button>
                      </div>
                      {/* Render answer only if question is expanded */}
                      {expandedQuestions.includes(faq.id) && (
                        <p>{faq.answer}</p>
                      )}
                    </li>
                  ))}
                </ul>
                {/* </div> */}
              </>
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </>
      )}

{showAlert && (
        <CenteredAlert
          message="We have received your request, please allow us up-to 24 Hrs. to come back to you."
          onClose={() => setShowAlert(false)}
        />
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{width: "35%", left: "30%"}}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Book Appointment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={iframeUrl}
            width="100%"
            height="650px"
            title="Appointment Booking"
            frameBorder="0"
            allowFullScreen
          />
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>Close</Button>
        </Modal.Footer> */}
      </Modal>

      
      <Modal
        show={showModals}
        onHide={() => setShowModals(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{width: "35%", left: "30%"}}
      >
          <span style={{display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1.4rem", fontWeight: "600", marginBottom: "1rem", color: "#005c74"}}>
            Book Appointment
          </span>
        
        <h1 style={{fontSize: "1.4rem", fontWeight: "600", textAlign: "center"}} >We will call you to confirm your booking with the {doctordata?.doctor_card?.doctor_name}
        </h1>
        <Modal.Body>
        <form
                  style={{ textAlign: "center"}}
                  onSubmit={handlesubmit}
                >
                  <TextField
                    id="firstName"
                    name="first_name"
                    label="Full Name"
                    variant="filled"
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    fullWidth
                    className={classes.nameField}
                  />
                  <FormControl
                    variant="filled"
                    fullWidth
                    className={classes.nameField}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="w-23"
                        onChange={handlePhoneCodeChange}
                        value={phoneCode} // Concatenate phoneCode and phone here
                        classes={{ select: classes.select }}
                      >
                        {countriesList &&
                          countriesList.map((item, index) => (
                            <MenuItem
                              value={item.code}
                              key={item.code + "-" + index}
                            >
                              {item.code}
                            </MenuItem>
                          ))}
                      </Select>

                      <InputLabel
                        htmlFor="phoneNumber"
                        style={{ marginLeft: "101px" }}
                      >
                        Phone Number*
                      </InputLabel>
                      <Input
                        id="phoneNumber"
                        name="phone"
                        label="Phone Number"
                        required
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        error={phoneError}
                        style={{ flex: 1, marginLeft: 6, width: "100px" , marginBottom: "1rem"}} // Adjust the margin and flex properties
                        className={classes.nameField}
                      />
                    </div>
                    {phoneError && (
                      <FormHelperText error>
                        Please enter a valid phone number.
                      </FormHelperText>
                    )}
                  </FormControl>
          

                  <TextField
                    id="chiefComplaints"
                    name="message"
                    label="Decribe Your Medical Problem"
                    variant="filled"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    fullWidth
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    className={classes.nameField}
                  />
                         

            
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Submit
                  </Button>
                </form>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Doctorprofile;
