import React, { useEffect, useState, useContext, useRef } from "react";
import "../Css/booking.css";
import doctorTemplate from "../Images/doctor-template.png";
import "aos/dist/aos.css";
import AOS from "aos";
import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined";
import ServiceCall from "../ServiceCall";
import AppointmentScreen from "./AppointmentScreen";
import { Modal, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Doctorprofile from "./Doctorprofile";
import Doctoreprofilee from "./Doctoreprofilee";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import GradingIcon from "@mui/icons-material/Grading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import { useMediaQuery } from "@material-ui/core";
import CenteredAlert from "./CenteredAlert";
import {
  TextField,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@material-ui/core";
import { countriesList } from "../utility/constants";
import emailjs from "emailjs-com";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
  salutationFormControl: {
    width: "27%", // Adjust the width of the salutation select
  },
  gender: {
    width: "30%",
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  nameField: {
    background: "white",
  },
  select: {
    border: "none", // Remove border
    width: "50px", // Adjust the width as needed
  },
}));
function Booking() {
  const [show, setShow] = useState(false);
  const mode = process.env.REACT_APP_MODE;
  const book_Appointment = process.env.REACT_APP_BOOK_WIDGET;
  const patientportal = process.env.REACT_APP_PATIENTPORTAL;
  const [details, setDetails] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [doctorID, setDoctorID] = useState("");
  const [hospitalID, setHospitalID] = useState("");
  const [doctorDetails, setDoctorDetails] = useState("");
  const [doctorDetail, setDoctorDetail] = useState("");
  const [showAppointment, setShowAppointment] = useState(false);
  const [showPerson, setPerson] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const { route_key } = useParams();
  const [iframeUrl, setIframeUrl] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [limit] = useState(10); // Number of doctors per page
  const [hasMore, setHasMore] = useState(true); // Whether there are more doctors to load

  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const navigate = useNavigate();
  const { code } = useParams();

  const location = useLocation(); // Access location state

  const [callBackFun, setCallBackFun] = useState("");
  const [lookup, setLookup] = useState([]); // State to store fetched records
  const [selectedFilter, setSelectedFilter] = useState("MODE"); // const [search, setSearch] = useState("");

  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [pin, setPin] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const[docname, setDocname] = useState("");
  const isValidPhoneNumber = (phoneNumber) => {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
    return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
  };
  const [searchResults, setSearchResults] = useState(null);


  const showAlerts = () => {
    setShowAlert(true);
    resetForm();
  };

  const resetForm = () => {
    setName("");
    setMessage("");
    setPhone("");
    setShowModals(false)
  };

  const handlesubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const serviceid = "service_7yxtqsn";
    const templateid = "template_x3wusd7";
    const publickey = "4UpyJJj3P5JGeMZFz";
    const messageContent = `
    Type: 'Appointment Booking For - ${docname}'
    Patient Name: ${name}
    Phone: ${phoneCode}${phone}
    Problem: ${message}

  `;

    const templateparams = {
      from_name: name,
      from_email: email,
      to_name: "web",
      message: messageContent, // Include the messageContent here
    };

    emailjs
      .send(serviceid, templateid, templateparams, publickey)
      .then((response) => {
        setLoading(false);
        console.log("email set successfully", response);
        showAlerts();
        
      })
      .catch((error) => {
        setLoading(false);
        console.log("error sending email", error);
      });
  }; 
  
  
  // useEffect(() => {
  //   if (callBackFun == "data") {
  //     data();
  //     AOS.init({ duration: 500 });
  //     setCallBackFun("");
  //   }
  // }, [callBackFun]);

  // useEffect(() => {
  //   const data = async () => {
  //     try {
  //       const result = await ServiceCall.gett(
  //         `/v2/patient/open/list/available/doctor/${mode}?som=${code}`
  //       );
  //       const dataa = result.data.doctors;
  //      setDetails(dataa);
  //     } catch (err) {}
  //   };
  //   data();
  // }, [code]);
  const isMobile = useMediaQuery("(max-width:710px)");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlePhoneCodeChange = (event) => {
    setPhoneCode(event.target.value);
  };


  const handleID = (res, event) => {
    console.log(3334444, res.doctor_name)
    setDocname(res.doctor_name)
    // setDoctorID(res.doc_id);
    // setHospitalID(res.hos_id);
    // setDoctorDetails(res);
    // setPerson(event);
    if(res.show_booking_button === false){

      setShowModals(true)
    }else if(res.show_booking_button === true){

      const url = `${book_Appointment}${res.route_key}`;
      setIframeUrl(url);
      setShowModal(true);
    }

  };

  const searchData = (e) => {
    e.preventDefault();
    if (search.trim() !== "") {
      const filteredDoctors = details.filter((doctor) =>
        doctor.som.toLowerCase().includes(search.toLowerCase())
      );
      // setDetails(filteredDoctors);
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (value == 1) {
      const sort = [...details].sort(function (a, b) {
        return b.experience - a.experience;
      });
      setDetails(sort);
    } else if (value == 2) {
      const sort = [...details].sort(function (a, b) {
        return a.experience - b.experience;
      });
      setDetails(sort);
    } else if (value == 3) {
      const sort = [...details].sort(function (a, b) {
        return a.doctor_name.toLowerCase() > b.doctor_name.toLowerCase()
          ? 1
          : -1;
      });
      setDetails(sort);
    }
  };
  const doctorpro = (res) => {
    const profileUrl = `/profile/${res.route_key}`; // Include route_key in the URL

    // Navigate to the profile page with state
    window.open(profileUrl, '_blank');
    // console.log("speciality", res)
  };
  const unicode = "\u20B9";

  const handleregis = () => {
    navigate("/treatment-advice");
  };

  // Function to fetch doctors based on the current page
  const fetchDoctors = async (currentPage) => {
    setLoading(true);
    try {
      const response = await ServiceCall.gett(
        `/v2/patient/open/pagination/list/available/doctor/${mode}/${currentPage}/${limit}?som=${selectedFilter}`
      );
      const newDoctors = response.data.doctors;

      // If fewer doctors than the limit are returned, no more data is available
      if (newDoctors.length < limit) {
        setHasMore(false);
      }

      setDetails((prevDoctors) => [...prevDoctors, ...newDoctors]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching doctors:", error);
      setLoading(false);
    }
  };

  const handlesearch = async () => {
    if (!search.trim()) return;
    
    setLoading(true);
    try {
      const response = await ServiceCall.gett(
        `/v2/patient/open/pagination/list/available/doctor/${mode}/${page}/${limit}?som=${selectedFilter}&name=${search}`
      );
      const searchDoctors = response.data.doctors;
      setSearchResults(searchDoctors);
      setHasMore(searchDoctors.length === limit);
      setLoading(false);
    } catch (err) {
      console.error("Search error:", err);
      setLoading(false);
    }
  };

  // useEffect(() =>{
  const fetchdoctordetail = async () => {
    try {
      const doctorsdet = await ServiceCall.gett(
        `/rxopd/count/doctor/som/${mode}/${selectedFilter} `
      );
      if (doctorsdet) {
        setDoctorDetail(doctorsdet?.data);
      }
    } catch (err) {}
  };

  // }, [])
  // Load the next page when user scrolls near the bottom
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = window.innerHeight;

    if (scrollTop + clientHeight >= scrollHeight - 100 && !loading && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page to load more doctors
    }
  };

  // Fetch doctors when page changes
  useEffect(() => {
    if (hasMore) {
      fetchDoctors(page);
    }
  }, [page]);
  useEffect(() => {
    fetchdoctordetail();
  }, []);

  // Attach scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore]);

  useEffect(() => {
    const lookups = async () => {
      try {
        const lookupdata = await ServiceCall.get(
          "/v2/facility/som/open/lookup"
        );
        if (lookupdata) {
          setLookup(lookupdata.data.records);
        }
      } catch (err) {}
    };
    lookups();
  }, []);

  const handleFilterChange = async (e) => {
    const selectedCode = e.target.value;
    setSelectedFilter(selectedCode);
    setPage(1);
    setDetails([]);
    setHasMore(true);

    if (selectedCode) {
      try {
        // First API call
        const response = await ServiceCall.gett(
          `/v2/patient/open/pagination/list/available/doctor/${mode}/${page}/${limit}?som=${selectedCode}`
        );
        const newDoctors = response.data.doctors;

        if (newDoctors.length < limit) {
          setHasMore(false);
        }

        setDetails((prevDoctors) => [...prevDoctors, ...newDoctors]);
        console.log("API Response:", response.data);

        // Second API call
        const response1 = await ServiceCall.gett(
          `/rxopd/count/doctor/som/${mode}/${selectedCode}`
        );
        if (response1) {
          setDoctorDetail(response1?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };


  const displayData = searchResults || details;

  return (
    <>
      {/* <!-- Banner Section --> */}
      <section className="ms-book-banner d-flex align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="offset-md-3 col-md-6" style={{ height: "105px" }}>
              <h3 className="mb-3 ms-font-weight-600 text-center">
                Search Specialists
              </h3>
              <form
                onSubmit={searchData}
                className="position-relative ms-form-layout d-flex"
              >
                {/* Dropdown for selecting category */}
                <select
                  className="form-select me-2"
                  onChange={handleFilterChange} // Call handleFilterChange when selection is made
                  value={selectedFilter}
                  style={{
                    width: isMobile ? "100%" : "30%",
                    marginTop: isMobile ? "8px" : "",
                  }}
                >
                  <option value="">Select Speciality</option>
                  {lookup.map((item) => (
                    <option key={item.code} value={item.code} style={{fontSize: isMobile ? "16px" : ""}}>
                      {item.title} {/* Map the title from the fetched data */}
                    </option>
                  ))}
                </select>

                {/* Input field for search */}
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Speciality, Doctors, Clinics"
                  style={{
                    marginTop: isMobile ? "8px" : "",
                    width: isMobile ? "270%" : "",
                    height: isMobile ? "10px" : "",
                  }}
                />

                {/* Submit button */}
                <button
                  style={{
                    height: "100%",
                    marginLeft: isMobile ? "-7px" : "10px",
                    borderRadius: "0",
                    width: isMobile ? "31%" : "",
                  }}
                  type="submit"
                  className="ms-secondary-btn mt-2 mt-md-0"
                  onClick={handlesearch}
                >
                  <i className="fas"></i> Search
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Appointment Section --> */}
      <span
        style={{
          display: "block",
          width: "100vw", // Use 100vw for full viewport width
          backgroundColor: "rgb(0 92 116)",
          color: "#ffffff",
          padding: "6px",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "21px",
          // marginTop: "53px",
          position: "relative", // Add position relative
          left: "50%", // Move the element 50% to the left
          right: "50%", // Move the element 50% to the right
          marginLeft: "-50vw", // Compensate the left movement
          marginRight: "-50vw", // Compensate the right movement
        }}
      >
        LIMITED TIME OFFER! Get{" "}
        <span style={{ color: "rgb(255, 203, 37)" }}>FREE</span> treatment
        advice from our experts
        <button className="handlrs" onClick={handleregis}>
          <span style={{ fontSize: "17px" }}>CONSULT NOW</span>
        </button>
      </span>
      {!show && (
        <section className="ms-appointment-section position-relative">
          <div className="container">
            <div className="row">
              <div className="col-md-12 p-4 bg-white">
                <div className="row mb-3">
                  <div className="col-6">
                    <h5 className="mb-1 ms-font-weight-600">All SPECIALISTS</h5>
                    <p className="ms-third-color mb-0">
                      {details.length}/{doctorDetail?.count} Matches Found
                    </p>
                  </div>
                  <div className="col-6 text-end">
                    <p className="ms-third-color mb-1 ms-dark-color fw-normal ms-sort-txt">
                      Sort by:
                    </p>
                    <select onChange={(e) => handleChange(e)}>
                      <option value="0">None</option>
                      <option value="1">High to Low</option>
                      <option value="2">Low to High</option>
                      <option value="3">Name</option>
                    </select>
                  </div>
                </div>

                {displayData.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ width: "100%" }}>
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  displayData.map((res, key) => (
                      <div
                        key={key}
                        className="ms-doctors"
                        style={{ cursor: "pointer" }}
                        onClick={() => doctorpro(res)}
                      >
                        <div className="row">
                          <div className="col-lg-12 pe-0">
                            <div className="row mb-3">
                              <div className="col-sm-12 col-md-auto">
                                <img
                                  src={ServiceCall.images(
                                    `v2/patient/doctor/pic/${mode}/${res.doc_id}`
                                  )}
                                  className="img-fluid ms-doc-img"
                                  alt="Image"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => doctorpro(res)}
                                />
                              </div>
                              <div
                                className="col-sm-12 col-md"
                                style={{ textAlign: "left" }}
                              >
                                <h1
                                  className="ms-font-weight-600 mb-1"
                                  style={{ fontSize: "20px" }}
                                >
                                  {res.doctor_name}
                                  <VisibilityIcon
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => doctorpro(res)}
                                  />
                                </h1>
                                <h6
                                  className="mb-0 text-break"
                                  style={{ fontWeight: "600" }}
                                >
                                  {res.speciality}
                                </h6>
                                {res.experience > 0 && (
                                  <p>
                                    {res.experience} Year
                                    {res.experience !== 1 ? "s" : ""} Experience
                                  </p>
                                )}
                                {res.consult_charges !== 0 && (
                                  <p
                                    className="mb-4"
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "23px",
                                      color: "black",
                                      marginRight: "107px",
                                    }}
                                  >
                                    {unicode}
                                    {res.consult_charges}
                                  </p>
                                )}
                              </div>

                              <div className="col-sm-12 col-md-3">
                                <div className="mb-3">
                                  {res.availability_today.clinic_start_time !==
                                    "" &&
                                    res.availability_today.clinic_end_time !==
                                      "" && (
                                      <p className="mb-0">
                                        <DirectionsWalkIcon
                                          style={{ color: "black" }}
                                        />
                                        <span
                                          style={{
                                            color: "#413f3f",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {res.availability_today?.clinic_days}
                                        </span>
                                        <br />
                                        {res.availability_today
                                          .clinic_start_time >= 12
                                          ? (
                                              24 -
                                              res.availability_today
                                                .clinic_start_time
                                            ).toFixed(2)
                                          : res.availability_today
                                              .clinic_start_time}{" "}
                                        To{" "}
                                        {res.availability_today
                                          .clinic_end_time >= 12
                                          ? (
                                              24 -
                                              res.availability_today
                                                .clinic_end_time
                                            ).toFixed(2)
                                          : res.availability_today
                                              .clinic_end_time}
                                      </p>
                                    )}
                                </div>
                                <div className="mb-3">
                                  {res.availability_today.online_start_time !==
                                    "" &&
                                    res.availability_today.online_end_time !==
                                      "" && (
                                      <p className="mb-0">
                                        <VideoCameraFrontIcon
                                          style={{ color: "black" }}
                                        />
                                        <span
                                          style={{
                                            color: "#413f3f",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {res.availability_today?.online_days}
                                        </span>
                                        <br />
                                        {res.availability_today
                                          .online_start_time >= 12
                                          ? (
                                              24 -
                                              res.availability_today
                                                .online_start_time
                                            ).toFixed(2)
                                          : res.availability_today
                                              .online_start_time}{" "}
                                        To{" "}
                                        {res.availability_today
                                          .online_end_time >= 12
                                          ? (
                                              24 -
                                              res.availability_today
                                                .online_end_time
                                            ).toFixed(2)
                                          : res.availability_today
                                              .online_end_time}
                                      </p>
                                    )}
                                </div>
                              </div>

                              <div className="col-sm-12 col-lg-3 pe-0">
                                <div
                                  className="d-flex"
                                  style={{ flexDirection: "column" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginTop: "-8px",
                                      maxWidth: "211px",
                                    }}
                                  >
                                    <button
                                      className="tele__btn ms-border-rounded ms-btn"
                                      type="button"
                                      style={{
                                        backgroundColor: "#ffa000",
                                        color: "#fff",
                                        border: "2px solid #ffa000",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleID(res, "True", "Clinic");
                                      }}
                                    >
                                      <a>
                                      {/* {res.appointment_booking_button_title} */}
                                      Book Appointment
                                      </a>{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {res.online_discount_stmt !==
                            "Get upto 0% discount on advance booking!" && (
                            <div
                              style={{
                                backgroundColor: "#ffa000",
                                border: "3px solid #ffa000",
                                borderRadius: "5px",
                                width: "102%",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "18px",
                                  color: "#fff",
                                  textAlign: "center",
                                  marginBottom: "0px",
                                }}
                              >
                                <strong>{res.online_discount_stmt}</strong>
                              </p>
                            </div>
                          )}

                          <div
                            className="col-md-7 col-lg-12"
                            style={{
                              padding: "6px 20px",
                              margin: "auto",
                              marginBottom: "-23px",
                            }}
                          >
                            <p
                              className="mb-2"
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => window.open(res.google_maps_url)}
                            >
                              <AddLocationOutlinedIcon className="ms-icon ms-second-color me-1" />
                              {res.hospital}, {res.address}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                )}

                {/* Show loading spinner when fetching more doctors */}
                {loading && (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}

                {/* Show message when no more doctors are available */}
                {!hasMore && (
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    No more doctors to load.
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      {/*................. Appointment tab toggle from here......................  */}

      {show && (
        <div className="appointment__screen">
          <AppointmentScreen
            doctor={doctorID}
            hospital={hospitalID}
            isPerson={showPerson}
            AllDoctorDetails={doctorDetails}
            close={handleClose}
          />
        </div>
      )}
        {showAlert && (
        <CenteredAlert
          message="We have received your request, please allow us up-to 24 Hrs. to come back to you."
          onClose={() => setShowAlert(false)}
        />
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{width: "35%", left: "30%"}}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Book Appointment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={iframeUrl}
            width="100%"
            height="650px"
            title="Appointment Booking"
            frameBorder="0"
            allowFullScreen
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showModals}
        onHide={() => setShowModals(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{width: "35%", left: "30%"}}
      >
         <span style={{display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1.4rem", fontWeight: "600", marginBottom: "1rem", color: "#005c74"}}>
            Book Appointment
          </span>
        
        <h1 style={{fontSize: "1.4rem", fontWeight: "600", textAlign: "center"}} >We will call you to confirm your booking with the {docname}
        </h1>
        <Modal.Body>
        <form
                  style={{ textAlign: "center"}}
                  onSubmit={handlesubmit}
                >
                  <TextField
                    id="firstName"
                    name="first_name"
                    label="Full Name"
                    variant="filled"
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    fullWidth
                    className={classes.nameField}
                  />
                  <FormControl
                    variant="filled"
                    fullWidth
                    className={classes.nameField}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="w-23"
                        onChange={handlePhoneCodeChange}
                        value={phoneCode} // Concatenate phoneCode and phone here
                        classes={{ select: classes.select }}
                      >
                        {countriesList &&
                          countriesList.map((item, index) => (
                            <MenuItem
                              value={item.code}
                              key={item.code + "-" + index}
                            >
                              {item.code}
                            </MenuItem>
                          ))}
                      </Select>

                      <InputLabel
                        htmlFor="phoneNumber"
                        style={{ marginLeft: "101px" }}
                      >
                        Phone Number*
                      </InputLabel>
                      <Input
                        id="phoneNumber"
                        name="phone"
                        label="Phone Number"
                        required
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        error={phoneError}
                        style={{ flex: 1, marginLeft: 6, width: "100px" , marginBottom: "1rem"}} // Adjust the margin and flex properties
                        className={classes.nameField}
                      />
                    </div>
                    {phoneError && (
                      <FormHelperText error>
                        Please enter a valid phone number.
                      </FormHelperText>
                    )}
                  </FormControl>
          

                  <TextField
                    id="chiefComplaints"
                    name="message"
                    label="Decribe Your Medical Problem"
                    variant="filled"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    fullWidth
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    className={classes.nameField}
                  />
                         

            
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Submit
                  </Button>
                </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Booking;
